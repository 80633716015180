<style>
.title-panel .el-icon-info{color:#999; font-size:32px; margin-right:5px;}

.param #table-box{background:#fff; margin-top:5px; padding:20px; height:100%;}
.param .box-card{width: 400px;}
.param .el-card__header{background: #f1f6ff}
.param .card-header{display:flex; align-items: center;}
.param .subject{flex:1 1 auto; font-weight: bold;}
.param .memo{font-size:13px; line-height: 26px; min-height:78px;}
.param .el-icon-warning{font-size:15px; color:#7370ae; margin-right:5px;}
.param .memo .el-icon-info{font-size:15px; color:#7370ae; margin-right:5px;}
.param .memo .red{color:#F56C6C !important;}
.param .memo .green{color:#67C23A !important;}
</style>
<template>
    <div class="component param">

        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">参数配置</div><div class="descript">配置系统运行参数</div></div>
                </el-col>
            </el-row>
        </div>
        <div id="table-box">
        	<el-space wrap alignment="flex-start">
        		<el-card class="box-card">
					<template #header>
						<div class="card-header">
							<span class="subject">强制修改默认密码</span>
							<el-switch v-model="change_pass" active-value="1" active-text="开启" inactive-value="0" inactive-text="关闭" @change="click_change_pass"></el-switch>
						</div>
					</template>
					<div class="memo">
						<i class="el-icon-info green"></i>文件导入生成的业主账号默认密码：{{def_pass}}。<br>
						<i class="el-icon-info"></i>开启以强制业主修改密码。<br>
						<i class="el-icon-warning red"></i>与默认密码相同也不行。<br>
					</div>
				</el-card>

	        	<el-card class="box-card">
					<template #header>
						<div class="card-header">
							<span class="subject">强制验证手机号</span>
							<el-switch v-model="verify_phone" active-value="1" active-text="开启" inactive-value="0" inactive-text="关闭" @change="click_verify_phone"></el-switch>
						</div>
					</template>
					<div class="memo">
						<i class="el-icon-info"></i>开启强制进行手机号短信验证。<br>
						<i class="el-icon-warning red"></i>未验证通过会再次验证，成功为止。<br>
						<i class="el-icon-warning red"></i>每次消耗1条短信，多次验证会多次消耗。<br>
					</div>
				</el-card>

	    		<el-card class="box-card">
					<template #header>
						<div class="card-header">
							<span class="subject">允许业主自行注册【未生效】</span>
							<el-switch disabled v-model="allow_reg" active-value="1" active-text="开启" inactive-value="0" inactive-text="关闭" @change="click_allow_reg"></el-switch>
						</div>
					</template>
					<div class="memo">
						<i class="el-icon-warning red"></i>注册需验证手机号，下发送验证码，消耗1条短信余量。<br>
						<i class="el-icon-warning red"></i>未验证通过会再次验证，多次验证会多次消耗。<br>
						<i class="el-icon-info"></i>注册成功后，需要物业人员审核通过后才能启用。
					</div>
				</el-card>
			</el-space>

        </div>
    </div>
</template>
<script>

export default {
  data:function(){
    return {
    	inited:false,
    	def_pass:'',
    	change_pass:0,
    	verify_phone:0,
        allow_reg:0,
    }
  },//data end
  watch:{
  	// allow_reg:function(val){
  	// 	this.set_para('allow_reg', val);
  	// 	console.log(val);
  	// }
  },//watch
  mounted:function(){
    //this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
    this.init();
  },//mounted end
  methods:{
    init: function(){
    	this.get_def_pass();
     	this.load_list();
    },//init
    get_def_pass:function(){
      this.axios.post("/api/param/def_pass").then(ret=>{
        if(!ret.ok){this.$message.error(ret.msg); return;}
        this.def_pass = ret.data;
      });
    },//load_list
    load_list:function(){
      this.axios.post("/api/param/list").then(ret=>{
        if(!ret.ok){this.$message.error(ret.msg); return;}
        this.list = ret.data;
        ret.data.map(item=>{
        	if(item.key == 'allow_reg'){ this.allow_reg = item.val; }
        	if(item.key == 'change_pass'){ this.change_pass = item.val; }
        	if(item.key == 'verify_phone'){ this.verify_phone = item.val; }
        });
        this.inited = true;

      });
    },//load_list
    click_change_pass:function(val){
    	if(!this.inited){ return; }
		if(val == '' ){ this.$message.error('缺少配置参数值'); return; }
		this.axios.post("/api/param/edit",{key:'change_pass',val:val}).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
		});
    },//click_change_pass
    click_verify_phone:function(val){
    	if(!this.inited){ return; }
		if(val == '' ){ this.$message.error('缺少配置参数值'); return; }
		this.axios.post("/api/param/edit",{key:'verify_phone',val:val}).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
		});
    },//click_verify_phone
    click_allow_reg:function(val){
    	if(!this.inited){ return; }
		if(val == '' ){ this.$message.error('缺少配置参数值'); return; }
		this.axios.post("/api/param/edit",{key:'allow_reg',val:val}).then(ret=>{
			if(!ret.ok){this.$message.error(ret.msg); return;}
			this.$message.success('操作成功');
		});
    },//click_allow_reg
  },//methods end
};
</script>